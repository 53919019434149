@font-face {
  font-family: 'IBMPlexMono-Bold';
  src: local('IBMPlexMono-Bold'), url(./fonts/IBMPlexMono-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'IBMPlexMono-BoldItalic';
  src: local('IBMPlexMono-BoldItalic'), url(./fonts/IBMPlexMono-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'IBMPlexMono-ExtraLight';
  src: local('IBMPlexMono-ExtraLight'), url(./fonts/IBMPlexMono-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'IBMPlexMono-ExtraLightItalic';
  src: local('IBMPlexMono-ExtraLightItalic'), url(./fonts/IBMPlexMono-ExtraLightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'IBMPlexMono-Italic';
  src: local('IBMPlexMono-Italic'), url(./fonts/IBMPlexMono-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'IBMPlexMono-Light';
  src: local('IBMPlexMono-Light'), url(./fonts/IBMPlexMono-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'IBMPlexMono-LightItalic';
  src: local('IBMPlexMono-LightItalic'), url(./fonts/IBMPlexMono-LightItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'IBMPlexMono-Medium';
  src: local('IBMPlexMono-Medium'), url(./fonts/IBMPlexMono-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'IBMPlexMono-MediumItalic';
  src: local('IBMPlexMono-MediumItalic'), url(./fonts/IBMPlexMono-MediumItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'IBMPlexMono-Regular';
  src: local('IBMPlexMono-Regular'), url(./fonts/IBMPlexMono-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'IBMPlexMono-SemiBold';
  src: local('IBMPlexMono-SemiBold'), url(./fonts/IBMPlexMono-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'IBMPlexMono-SemiBoldItalic';
  src: local('IBMPlexMono-SemiBoldItalic'), url(./fonts/IBMPlexMono-SemiBoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'IBMPlexMono-Thin';
  src: local('IBMPlexMono-Thin'), url(./fonts/IBMPlexMono-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'IBMPlexMono-ThinItalic';
  src: local('IBMPlexMono-ThinItalic'), url(./fonts/IBMPlexMono-ThinItalic.ttf) format('truetype');
}

.thinFont {
  font-family: IBMPlexMono-Thin;
}

.regularFont {
  font-family: IBMPlexMono-Regular;
}

.whiteFont {
  color: white;
}

.App {
  font-family: IBMPlexMono-Regular;
  text-align: center;
  background-color: #1E1E24;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.test {
  font-size: 50px;
  color: red;
}


.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  color: white;
  font-family: IBMPlexMono-Regular;
  background-color: #1E1E24;
}

.App-link {
  color: #61dafb;
}

